var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Datos de Usuario",
            "solicitar-confirmacion-close": false
          }
        },
        [
          _vm.cargando
            ? _c("span", [_vm._v("Cargando...")])
            : _c("div", [
                _vm.datos
                  ? _c("table", { staticClass: "tabla-info" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Usuario")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.username)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Nombre y Apellido")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.nombre + " " + _vm.datos.apellido
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("DNI")]),
                          _c("td", {
                            domProps: { textContent: _vm._s(_vm.datos.dni) }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Email")]),
                          _c("td", {
                            domProps: { textContent: _vm._s(_vm.datos.email) }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Calle")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.calle +
                                  (_vm.datos.nro != null
                                    ? " " + _vm.datos.nro
                                    : "") +
                                  (_vm.datos.piso != null
                                    ? ", " + _vm.datos.piso
                                    : "") +
                                  (_vm.datos.dpto != null
                                    ? ", " + _vm.datos.dpto
                                    : "")
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Bloqueado")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.bloqueado === 1 ? "Si" : "No"
                              )
                            }
                          })
                        ]),
                        _vm.datos.bloqueadoSeguridadExpira != null
                          ? _c("tr", [
                              _c("th", [_vm._v("Bloqueo fecha y hora expira")]),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.datos.bloqueadoSeguridadExpira
                                      ? _vm.$common.formatearFechaHora(
                                          _vm.datos.bloqueadoSeguridadExpira
                                        )
                                      : ""
                                  )
                                }
                              })
                            ])
                          : _vm._e(),
                        _c("tr", [
                          _c("th", [_vm._v("Roles")]),
                          _vm.datos.roles != null
                            ? _c("td", [
                                _c(
                                  "table",
                                  { staticClass: "detalleRol" },
                                  _vm._l(_vm.datos.roles, function(
                                    item,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(item.nombre)
                                        }
                                      })
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _c("td")
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }