<template>
  <div>
    <maca-modal titulo="Modificar Usuario" :impedirClose="impedirClose" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Nombre" prop="nombre">
          <el-input maxlength="100" v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Apellido" prop="apellido">
          <el-input maxlength="100" v-model="form.apellido"></el-input>
        </el-form-item>
        <el-form-item label="DNI" prop="dni">
          <el-input-number :max="999999999" :min="1" v-model="form.dni" controls-position="right"></el-input-number>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input maxlength="100" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="Calle" prop="calle">
          <el-input maxlength="200" v-model="form.calle"></el-input>
        </el-form-item>
        <el-form-item label="Número" prop="nro">
          <el-input-number v-model="form.nro" controls-position="right"></el-input-number>
        </el-form-item>
        <el-form-item label="Piso" prop="piso">
          <el-input-number :max="999" v-model="form.piso" controls-position="right"></el-input-number>
        </el-form-item>
        <el-form-item label="Dpto" prop="dpto">
          <el-input maxlength="50" v-model="form.dpto"></el-input>
        </el-form-item>
        <el-form-item label="Nombre de Usuario" prop="username">
          <el-input maxlength="200" v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="Rol" prop="rol">
          <maca-select-box v-model="form.rol" :url="urlRol"></maca-select-box>
        </el-form-item>
        <el-form-item>
          <span>La <b>contraseña</b> será el <b>DNI</b> ingresado</span>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      form: {
        nombre: "",
        apellido: "",
        dni: "",
        email: "",
        calle: "",
        nro: "",
        piso: "",
        dpto: "",
        username: "",
        rol: null
      },
      impedirClose: false,
      cargando: false,
      id: 0,

      urlRol: this.$api.URL + "/user/obtenerTodosSelectRoles",

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change"
          }
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change"
          }
        ],
        dni: [
          {
            required: true,
            message: "Por favor introduzca el DNI.",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "change"
          }
        ],
        calle: [
          {
            required: true,
            message: "Por favor introduzca la calle.",
            trigger: "change"
          }
        ],
        nro: [
          {
            required: true,
            message: "Por favor introduzca el número.",
            trigger: "change"
          }
        ],
        rol: [
          {
            required: true,
            message: "Por favor introduzca el rol.",
            trigger: "change"
          }
        ],
        username: [
          {
            required: true,
            message: "Por favor introduzca el nombre de usuario.",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL + "/user/obtenerDatos?userID=" + this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.nombre;
        this.form.apellido = respuestaApi.apellido;
        this.form.dni = respuestaApi.dni;
        this.form.email = respuestaApi.email;
        this.form.calle = respuestaApi.calle;
        this.form.nro = respuestaApi.nro + ""; // convertir a str, api necesita
        this.form.piso = respuestaApi.piso;
        this.form.dpto = respuestaApi.dpto;
        this.form.username = respuestaApi.username;
        this.form.rol = {
          id: respuestaApi.roles[0].rolID,
          nombre: respuestaApi.roles[0].nombre
        };
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        userID: this.id,
        nombre: this.form.nombre,
        apellido: this.form.apellido,
        dni: this.form.dni,
        email: this.form.email,
        calle: this.form.calle,
        nro: this.form.nro,
        username: this.form.username,
        rolID: this.form.rol.id
      };

      if (this.form.piso != "" && this.form.piso != null) {
        params.piso = this.form.piso;
      }

      if (this.form.dpto != "" && this.form.dpto != null) {
        params.dpto = this.form.dpto;
      }

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/user/actualizar",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Empleado actualizado con éxito!",
          type: "success"
        });

        return true;
      } else if (respuestaApi.estado === 0) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    }
  }
};
</script>
