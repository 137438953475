<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-user"></i>Usuarios
        </h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="$refs.modalNuevo.abrir()"
        >Nuevo Usuario</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="100">
            <template slot="header">
              <maca-datatable-filtrar label="Usuario" v-model="filtroUsuario"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.username"></span>
            </template>
          </el-table-column>
          <el-table-column label="DNI" :min-width="60">
            <template slot-scope="props">
              <span v-text="props.row.dni"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120">
            <template slot="header">
              <maca-datatable-filtrar label="Nombre" v-model="filtroNombre"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.nombre + ' ' + props.row.apellido"></span>
            </template>
          </el-table-column>
          <el-table-column label="Email" :min-width="120">
            <template slot="header">
              <maca-datatable-filtrar label="Email" v-model="filtroEmail"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.email"></span>
            </template>
          </el-table-column>
          <el-table-column label="Rol" :min-width="110">
            <template slot="header">
              <maca-datatable-filtrar-select label="Rol" v-model="filtroRol" :urlSelect="urlRol"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <div v-if="props.row.roles != null" v-for="(item, index) in props.row.roles" :key="index">
                <el-tag
                    style="margin-top: 3px; margin-bottom: 3px"
                    type="info"
                    v-text="item.nombre"
                ></el-tag>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="Ver" width="70">
            <template slot-scope="props">
              <el-button
                  type="success"
                  size="small"
                  @click="$refs.modalVer.abrir(props.row.id)"
                  plain
                  round
              >
                <i class="el-icon-view"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                size="small"
                @click="$refs.modalModificar.abrir(props.row.id)"
                plain
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button type="danger" size="small" @click="eliminar(props.row.id)" plain round>
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-ver ref="modalVer" @actualizar-tabla="actualizarTabla = true"></modal-ver>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
  </div>
</template>

<script>
import ModalVer from "./modales/ver";
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalVer,
    ModalNuevo,
    ModalModificar
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/user/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      urlRol: this.$api.URL + "/user/obtenerTodosSelectRoles",

      filtroUsuario: null,
      filtroNombre: null,
      filtroEmail: null,
      filtroRol: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el usuario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { userID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/user/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Usuario borrado con éxito!",
          type: "success"
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      }
      //
    },
  },
  watch: {
    filtroUsuario() {
      if (this.filtroUsuario != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "username",
            this.filtroUsuario
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "username");
      }

      this.actualizarTabla = true;
    },
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "nombre",
            this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    },
    filtroEmail() {
      if (this.filtroEmail != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "email",
            this.filtroEmail
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "email");
      }

      this.actualizarTabla = true;
    },
    filtroRol() {
      if (this.filtroRol != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "rolID",
            JSON.stringify(this.filtroRol.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "rolID");
      }

      this.actualizarTabla = true;
    },
  },
};
</script>

